.container{
    width: var(--main-width);
    min-height: calc(100dvh - 310px);
    margin: 0 auto;
    overflow: hidden;
    height: fit-content;
    padding: 80px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .imageWrapper{
        height: 300px;
        width: var(--main-width);
        background-position: 50%;
        background-size: cover;
        border-radius: 10px;
        text-align: center;
        justify-content: center;
        align-items: center;
        display: flex;
        position: relative;
        z-index: -1;
        
        &::after{
            display: block;
            content: '';
            height: 100%;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.55);
            box-shadow: inset 0 0 15px rgba(29, 29, 29, 0.85);
            position: absolute;
            border-radius: 10px;
            z-index: 0;
        }

        h1{
            -webkit-text-fill-color: #00000000;
            background: linear-gradient(180deg, var(--primary-color-dark) 0,var(--text-color-4) 60%, #b1b1b1 100%);
            -webkit-background-clip: text;
            font-size: 2.5rem;
            font-weight: 500;
            margin: 0;
            text-align: center;
            color: var(--text-color-4);
            z-index: 1;
            text-transform: capitalize;
        }
    }

    p{
        width: 90%;
        margin-top: 40px;
        font-size: var(--text-m);
        color: var(--text-color-2);
        font-weight: 460;
        white-space: pre-line; 
        line-height: 1.32;
    }

    a{
        background-color: var(--primary-color);
        width: fit-content;
        height: fit-content;
        padding: 8px 14px;
        font-size: var(--text-s);
        color: var(--text-color-4);
        text-decoration: none;
        border-radius: 4px;
        font-weight: 300;
        margin-top: 10px;
    }

    .backArrow{
        margin-bottom: 10px;
        background-color: var(--background-shade-2);
        border: 0.5px solid var(--background-shade-3);
        svg{
            height: var(--text-m);
            width: var(--text-m);
            vertical-align: sub;
            color: var(--text-color-1);
        }
    }


    .keyFeatures{
        margin-top: 70px;
        display: flex;
        padding: 0 50px;
        gap: 40px;
        min-height: 350px;
        height: 350px;

        .imageBlock{
            width: 45%;
            border-radius: 10px;
            img{height: 350px; width: 100%; object-fit: cover; border-radius: 10px;}
        }

        .textBlock{
            width: 55%;

            h1{
                font-size: var(--text-xs);
                font-weight: 400;
                margin: 0 0 15px 0;
                color: var(--text-color-3);
                background-color: var(--background-shade-2);
                width: fit-content;
                padding: 5px 10px;
                border-radius: 5px;
            }
            h2{
                font-size: var(--text-xxxl);
                font-weight: 700;
                margin: 0 0 5px 0;
                color: var(--text-color-3);
                display: flex;
                align-items: center;
                gap: 5px;
            }
            span{
                font-size: var(--text-m);
                margin: 0 0 20px 0;
                color: var(--text-color-3);
                display: block;

            }
            p{
                font-size: var(--text-m);
                color: var(--text-color-2);
                font-weight: 460;
                line-height: 1.32;
                white-space: pre-line;
                margin: 0 0 5px 0 ;
                display: flex;
                gap: 5px;

                svg{
                    height: var(--text-s);
                    width: var(--text-s);
                    color: var(--text-color-1);
                    vertical-align: super;
                    background-color: var(--background-shade-3);
                    border-radius: 50%;
                    padding: 2px;
                }
            }
        }
    }
}



@media screen and (max-width: 900px){
    .container .keyFeatures{
        flex-direction: column;
        height: 700px;
        width: var(--main-width);
        padding: 0;

        .imageBlock{
            width: 100%;
        }
        .textBlock{
            width: 100%;
        }
    }
}