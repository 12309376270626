.container{
    padding-top: 50px;
    width: var(--main-width);
    margin: 0 auto;

    .heading{
        margin-top: 100px;
        width: 100%;
        text-align: center;
        h1{
            font-size: var(--text-xxxl);
            font-weight: 700;
            color: var(--text-color-1);
        }
    }

    .wrapper{
        height: fit-content;
        width: 80%;

        margin: 140px auto 80px;

        .card{
            display: flex;
            gap: 60px;
            padding: 50px 0;
            flex-direction: row;

            &:nth-child(odd){
                flex-direction: row-reverse;

                .content
                {
                    text-align: right;
                }
            }

            .image{
                height: 350px;
                width: 50%;
                min-width:540px;

                img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 10px;
                }

                video{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 10px;
                }

                .compareSlider{
                    height: 100%;
                    width: 100%;
                }
            }

            .content{
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;

                h2{
                    font-size: var(--text-xxl);
                    font-weight: 500;
                    color: var(--text-color-1);
                    margin: 0 0 5px 0;
                    text-align: left;
                }
                p{
                    font-size: var(--text-m);
                    color: var(--text-color-2);
                    margin: 0;
                    text-align: left;
                }
                a{
                    margin-top: 10px;
                    color: var(--text-color-3);
                    font-size: var(--text-s);
                    text-decoration: none;
                    background-color: var(--background-shade-2);
                    padding: 6px 12px;
                    border-radius: 4px;

                    svg{
                        vertical-align: text-bottom;
                        margin-left: 2px;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 760px){
    .container{
        .heading{
            h1{
                font-size: var(--text-xxl);
            }
        }

        .wrapper{
            margin: 80px auto 40px auto;

            .card{
                flex-direction: column;
                padding: 10px 0px;
                margin: 40px 0;

                &:nth-child(odd){
                    flex-direction: column;
                }

                .image{
                    height: 300px;
                    width: 100%;
                    min-width: 100%;
                }

                .content{
                    width: 100%;
                    padding: 0px;

                    h2{
                        font-size: var(--text-xl);
                    }
                    p{
                        font-size: var(--text-s);
                    }
                }
            }
        }
    }
}