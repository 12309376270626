body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Inter', sans-serif;
  background-color: var(--background-shade-1);
  max-width: 100vw;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');


:root{

  /*           Sizes
  */
  --main-width: 94dvw;
  --second-width: 94dvw;



  /*           Font Settings 
  */
  --text-xs: 0.75rem;
  --text-s: 0.85rem;
  --text-m: 0.95rem;
  --text-l: 1.05rem;
  --text-xl: 1.25rem;
  --text-xxl: 1.45rem;
  --text-xxxl: 1.95rem;


  /*           Colors 
  */
  --primary-color: #3d5840;
  --primary-color-dark: #2E5D57;

  --background-shade-1: #FFFFFF;
  --background-shade-2: #f5f5f5;
  --background-shade-3: #dedede;

  --text-color-1: #000000;
  --text-color-2: #1C1C1C;
  --text-color-3: #3C3C3C;

  --text-color-4: #ffffff;
  --text-color-5: #e8eae5;
}


@media screen and (max-width: 760px){
  :root{
      --main-width: 95dvw;

      --text-xs: 0.65rem;
      --text-s: 0.75rem;
      --text-m: 0.85rem;
      --text-l: 0.95rem;
      --text-xl: 1.05rem;
      --text-xxl: 1.35rem;
      --text-xxxl: 1.6rem;
  }
}