
.desktop{
    height: fit-content;
    max-width: 100dvw;
    width: 100dvw;
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 5;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding-top: 5px;
    padding-bottom: 5px;


    .upper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        width: var(--main-width);
        margin: 0 auto;

        .logo{
            height: 40px;
            width: 40px;
            img{
                height: 100%;
                width: 100%;
            }
        }

        .links{
            display: flex;
            gap: 20px;

            a{
                text-decoration: none;
                font-size: var(--text-m);
                font-weight: 450;
                color: var(--text-color-2);

                &:hover{
                    color: var(--primary-color);
                }

                &.active{
                    color: var(--primary-color);
                }
            }
        }

        .contact{
            padding: 8px 14px;
            background-color: var(--primary-color);
            color: var(--text-color-4);
            text-decoration: none;
            border-radius: 5px;
            font-size: var(--text-xs);
            transition: all .12s ease-in-out;

            &:hover{
                background-color: var(--primary-color-dark);
            }
        }
    }


    .lower{
        display: flex;
        justify-content: space-between;
        height: 40px;
        width: var(--main-width);
        margin: 10px auto 0 auto;


        &::before{
            content: '';
            display: flex;
            position: fixed;
            top: 0;
            left: 0;
            width: 100dvw;
            height: 100dvh;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.6) 100%);
            z-index: -1;
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
        }

        .search{
            display: flex;
            flex-direction: column;

            input{
                border: 1px solid var(--background-shade-2);
                background-color: var(--background-shade-3);
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.05);
                outline: none;
                padding: 10px 20px;
                border-radius: 20px;
                width: calc(var(--main-width) - 42px);
            }

            .results{
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                gap: 5px;
                a{
                    background-color: var(--background-shade-2);
                    padding: 10px 20px;
                    border-radius: 5px;
                    text-decoration: none;
                    color: var(--text-color-2);
                }
            }
        }
    }
}


.mobile{
    display: none;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 5;
    width: 100dvw;
    height: fit-content;
    padding: 5px 0 2px 0;

    .upper{
        height: 50px;
        width: var(--main-width);
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        .logo{
            height: 30px;
            width: 30px;
            margin: auto 0;

            img{
                height: 100%;
                width: 100%;
            }
        }

        .buttons{
            background-color: var(--background-shade-2);
            height: fit-content;
            border-radius: 20px;
            display: flex;
            gap: 10px;
            padding: 5px;

            .contact{
                background-color: var(--background-shade-3);
                padding: 10px 10px 6px 10px;
                border-radius: 50%;
                cursor: pointer;
                width: fit-content;
                color: var(--text-color-2);
            }

            .openMenu{
                background-color: var(--background-shade-3);
                padding: 10px 10px 6px 10px;
                border-radius: 50%;
                cursor: pointer;
                width: fit-content;

                div{
                    height: 2px;
                    width: 17px;
                    background-color: var(--text-color-2);
                    margin: 3px 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;

                    &:first-child{
                        margin: 2px 0 3px 0;
                    }

                }
            }
        }
    }

    .lower{
        background: linear-gradient(to bottom, var(--background-shade-1) 20%, rgba(255, 255, 255, 0.3) 100%);
        width: 100dvw;
        max-width: 100vw;

        .search{
            width: var(--main-width);
            margin: 10px auto 0 auto;
            display: flex;
            flex-direction: column;
            height: fit-content;
            padding-bottom: 10px;
            backdrop-filter: blur(8px);
            -webkit-backdrop-filter: blur(8px);


            input{
                border: 1px solid var(--background-shade-2);
                background-color: var(--background-shade-3);
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.05);
                outline: none;
                padding: 10px 20px;
                font-size: 16px;
                border-radius: 10px;
            }

            .results{
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                gap: 5px;
                a{
                    &:first-child{
                        border-top: none;
                    }
                    border-top: 0.5px solid black;
                    padding: 10px 20px;
                    text-decoration: none;
                    color: var(--text-color-2);
                }
            }
        }

        .menu{
            display: flex;
            flex-direction: column;
            width: var(--main-width);
            margin: 30px auto 0 auto;
            backdrop-filter: blur(8px);
            -webkit-backdrop-filter: blur(8px);
            height: fit-content;
            text-align: end;
            align-items: end;
            padding: 0 5px 30px 0;

            a{
                padding: 10px 20px;
                text-decoration: none;
                color: var(--text-color-2);
                font-size: 16px;
            }
        
        }
    }
}


@media screen and (max-width: 760px){
    .desktop{
        display: none;
    }
    .mobile {
        display: flex;
    }
}