.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    width: var(--main-width);
    margin: 0 auto;
    padding-top: 50px;

    .heading{
        margin-top: 100px;
        h1{
            font-size: var(--text-xxxl);
            font-weight: 650;
            color: var(--text-color-1);
        }
    }

    .informations{
        display: flex;
        gap: 70px;
        padding: 10px 30px;
        align-items: center;

        .cta{
            width: fit-content;

            a{
                text-decoration: none;
                padding: 10px 20px;
                background-color: var(--primary-color);
                color: var(--text-color-4);
                border-radius: 5px;
                font-weight: 500;
                font-size: var(--text-m);
                transition: background-color all 0.12s ease-in-out;

                &:hover{
                    background-color: var(--primary-color-dark);
                }
            }
        }

        .wrapper{

            .block{
                display: flex;
                gap: 20px;
                align-items: center;
                svg{
                    height: var(--text-xl);
                    width: var(--text-xl);
                }
                p{
                    font-size: var(--text-m);
                }
            }
        }
    }

    .howItWorks{
        width: 100%;

        margin-top: 40px;
        display: flex;
        justify-content: center;

        .wrapper{
            display: flex;
            flex-direction: column;
            gap: 20px;

            .step{
                display: flex;
                height: fit-content;
                gap: 15px;
                width: fit-content;

                .indicator{
                    height: 30px;
                    width: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    background-color: var(--primary-color);
                    color: var(--text-color-4);
                    font-size: var(--text-s);
                }

                .content{
                    background-color: var(--background-shade-3);
                    padding: 10px;
                    border-radius: 5px;
                    width: 400px;

                    h2{
                        margin: 0;
                        font-weight: 400;
                        font-size: var(--text-s);
                        line-height: 1.4;
                        color: var(--text-color-2);
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 760px){
    .container .informations{
        width: 100%;
    }
    .container .howItWorks .wrapper .step .content{
        width: 100%;
    }
}