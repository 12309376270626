.footer{
    background-color: var(--background-shade-2);
    padding: 20px 0 15px 0;
    display: flex;
    flex-direction: column;
    align-items: center;


    .wrapper{
        width: var(--main-width);
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--background-shade-3);
        padding: 20px 0;

        .company{
            width: auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
    
            h1{
                font-size: var(--text-xl);
                color: var(--text-color-1);
                margin: 0;
                font-weight: 600;
            }
            p{
                margin: 0;
                padding: 0;
                font-size: var(--text-xs);
                color: var(--text-color-2);
                font-weight: 500;
            }
        }
    
        .content{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: var(--main-width);
            margin: 0 0 0 auto;
            gap: 80px;
            width: auto;
    
            a{
                text-decoration: none;
                color: var(--text-color-2);
                font-size: var(--text-xs);
    
                &:hover{
                    color: var(--primary-color);
                }
            }
        }
    }

    h1{
        font-size: var(--text-s);
        color: var(--text-color-1); 
        margin: 20px 0 0 0;
        font-weight: 400;
        
        a{
            text-decoration: none;
            color: var(--primary-color);
        }
    }
}

@media screen and (max-width: 760px){
    .footer .wrapper{
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 20px 0;
        .company{
            align-items: center;
            text-align: center;
        }
        .content{
            margin: 0;
            gap: 25px;
        }
    }
}