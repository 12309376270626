.container{
    height: fit-content;
    max-width: 100dvw;
    width: 100vw;
    padding-top: 60px;

    .hero{
        width: var(--main-width);
        margin: 0 auto;
        border-radius: 20px;
        overflow: hidden;

        .imageContext{
            display: flex;
            border-radius: 20px;
            position: relative;
            background: none;
            height: 650px;
            z-index: 0;

            img{
                width: 100%;
                height: 650px;
                object-fit: cover;
                border-radius: 20px;
                z-index: 0;
                position: absolute;
                top: 0;
                z-index: -2;
            }

            &::after{
                content: '';
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
                border-radius: 20px;
                width: var(--main-width);
                height: 650px;
                position: absolute;
                z-index: -1;
                top: 0;
            }

            .overlay{
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: fit-content;
                margin-left: 110px;
                margin-top: 230px;
                z-index: 1;
                user-select: none;

                h1{
                    margin: 0;
                    color: var(--text-color-4);
                    font-size: var(--text-xxxl);
                    font-weight: 600;
                }

                p{
                    margin: 10px 0 0 0;
                    color: var(--text-color-5);
                    font-size: var(--text-m);
                }

                a{
                    margin-top: 25px;
                    padding: 10px 20px;
                    background-color: var(--primary-color);
                    color: white;
                    text-decoration: none;
                    border-radius: 5px;
                    font-size: var(--text-s);
                    transition: all .12s ease-in-out;
                    width: fit-content;

                    &:hover{
                        background-color: var(--primary-color-dark);
                    }
                }
            }
        }
    }

    .services{
        padding-top: 120px;
        width: var(--main-width);
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        .content{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;


            h1{
                margin: 0;
                font-size: var(--text-xxxl);
                color: var(--text-color-3);
                font-weight: 600;
            }
            p{
                margin: 10px 0 0 0;
                font-size: var(--text-l);
                color: var(--text-color-2);
            }
        }

        .cards{
            display: flex;
            justify-content: space-around;
            margin-top: 70px;

            .card{
                max-width: 310px;
                padding: 30px;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                border-radius: 20px;

                .icon{
                    background-color: var(--background-shade-2);
                    padding: 15px;
                    border-radius: 50%;
                    margin-bottom: 15px;
                    height: 25px;
                    width: 25px;

                    svg{
                        color: var(--primary-color);
                        height: 100%;
                        width: 100%;
                    }
                }

                h2{
                    margin: 0;
                    font-size: var(--text-xl);
                    color: var(--text-color-3);
                    font-weight: 600;
                }

                p{
                    margin: 5px 0 0 0;
                    font-size: var(--text-m);
                    color: var(--text-color-2);
                }
            }
        }
    }

    .preview{
        padding-top: 150px;
        width: var(--main-width);
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        h1{
            margin: 0;
            font-size: var(--text-xxxl);
            color: var(--text-color-2);
            font-weight: 540;
        }

        .images{
            display: flex;
            width: fit-content;
            margin: 0 auto;
            height: 300px;
            margin-top: 25px;
            overflow-x: scroll;
            gap: 25px;

            .image{
                height: 100%;
                width: 250px;
                border-radius: 20px;
                background-size: cover;
                background-position: center;
                overflow: hidden;
                object-fit: cover;

                &::after{
                    content: '';
                    border-bottom-left-radius: 20px;
                    border-bottom-right-radius: 20px;
                    width: 235px;
                    height: 55px;
                    position: absolute;
                    margin-top: 250px;
                    display: flex;
                    padding-left: 15px;
                    align-items: center;
                    background-color: var(--background-shade-2);
                    font-family: 'Inter', sans-serif;
                    font-size: var(--text-s);
                }


                &:nth-child(1){
                    background-image: url('https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2Fnew%2FWhatsApp%20Image%202024-07-21%20at%2017.48.43.jpeg?alt=media&token=6169c16a-c37f-439a-9162-af879c33b59a');
                    opacity: .95;

                    &::after{
                        content: 'Garten Gestaltung';
                    }

                    &:hover{
                        transition: all .12s ease-in-out;
                        opacity: 1;
                    }
                }

                &:nth-child(2){
                    background-image: url('https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2F2024-05-23-17_5937.jpeg?alt=media&token=ce46d7de-9e98-49e3-a3d6-494f2f65f0be');
                    opacity: .95;
                    &::after{
                        content: 'Verfugung';
                    }

                    &:hover{
                        transition: all .12s ease-in-out;
                        opacity: 1;
                    }
                }

                &:nth-child(3) {
                    background-image: url('https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2FScreenshot%202024-07-21%20at%205.58.31%E2%80%AFPM.png?alt=media&token=56ee5eaa-1c43-4f08-ad87-0e11802f1515');
                    opacity: .95;
                    position: relative;
                    &::after {
                        content: 'Steinreinigung';
                    }
                
                    &:hover {
                        transition: all .12s ease-in-out;
                        opacity: 1;
                    }
                }

                &:nth-child(4){
                    background-image: url('https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2Fnew%2FWhatsApp%20Image%202024-07-21%20at%2017.48.53.jpeg?alt=media&token=97863806-1f94-44ae-839d-9db68e18cdc3');
                    opacity: .95;
                    &::after{
                        content: 'Fassadenreinigung';
                    }

                    &:hover{
                        transition: all .12s ease-in-out;
                        opacity: 1;
                    }
                }
            }
        }

        a{
            margin: 45px 0 0;
            text-decoration: none;
            padding: 10px 20px;
            width: fit-content;
            color: var(--text-color-2);
            border: 1px solid var(--text-color-2);
            border-radius: 20px;
            font-size: var(--text-s);
            transition: all .12s ease-in-out;

            &:hover{
                background-color: var(--primary-color);
                border: 1px solid var(--primary-color);
                color: var(--text-color-4);
            }
            
        }
    }


    .about{
        width: var(--main-width);
        margin: 0 auto;
        display: flex;
        padding: 160px 0 0 0;

        .left{
            width: 100%;
            border-radius: 15px;
            background-color: var(--background-shade-2);
            padding: 100px;
            display: flex;
            flex-direction: column;

            h1{
                font-size: var(--text-s);
                margin: 0;
                color: var(--text-color-3);
                font-weight: 600;

            }

            h2{
                font-size: var(--text-xxxl);
                margin: 5px 0 0 0;
                color: var(--text-color-3);
                font-weight: 800;

            }

            p{
                font-size: var(--text-xl);
                color: var(--text-color-2);
            }
        }

        .right{
            width: 100%;
            border-radius: 15px;
            box-shadow: 0 0 15px 5px rgba(7, 12, 8, 0.1);
            margin-left: 30px;

            .image{
                height: 100%;
                width: 100%;
                border-radius: 15px;
                background-position: center;
                background-size: cover;
                background-image: url('https://firebasestorage.googleapis.com/v0/b/akos-one.appspot.com/o/ga-la-bau%2F2024-05-23_133337.jpeg?alt=media&token=08470834-2b39-47d4-ae34-83a86fc7e711');
            }
        }
    }


    .beforeAfter{
        height: 600px;
        width: var(--main-width);
        margin: 0 auto;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        padding-top: 100px;
        gap: 30px;

        .header{
            width: 50%;
            height: calc(100% - 135px);
            padding: 30px 40px;
            display: flex;
            flex-direction: column;
            h1{
                margin: 0;
                font-size: var(--text-xxxl);
                color: var(--text-color-2);
                font-weight: 550;
            }

            p{
                margin: 10px 0 0 0;
                font-size: var(--text-l);
                color: var(--text-color-2);
            }

            .buttons{
                margin-top: 25px;
                display: flex;
                gap: 15px;

                a{
                    padding: 10px 20px;
                    text-decoration: none;
                    border-radius: 5px;
                    font-size: var(--text-s);
                    transition: all .12s ease-in-out;
                    width: fit-content;

                    &:first-child{
                        background-color: var(--primary-color);
                        color: white;

                        &:hover{
                            background-color: var(--primary-color-dark);
                        }
                    }

                    &:last-child{
                        background-color: transparent;
                        border: 1px solid var(--text-color-3);
                        color: var(--text-color-3);

                        &:hover{
                            background-color: var(--text-color-3);
                            color: var(--text-color-4);
                        }
                    }
    
                    
                }
            }
        }

        .compareWrapper{
            height: fit-content;
            width: 50%;
            
            .compareSlider{
                border-radius: 20px;
                height: 500px;
                width: calc(var(--main-width) / 2);
            }
        }
    }


    .contact{
        padding: 150px 0 100px 0;
        width: var(--main-width);
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        h1{
            margin: 0;
            font-size: var(--text-xxxl);
            color: var(--text-color-2);
            font-weight: 600;
        }
        p{
            margin: 10px 0 0 0;
            font-size: var(--text-l);
            color: var(--text-color-2);
        }
        a{
            margin-top: 25px;
            padding: 10px 20px;
            background-color: var(--primary-color);
            color: white;
            text-decoration: none;
            border-radius: 5px;
            font-size: var(--text-s);
            transition: all .12s ease-in-out;
            width: fit-content;

            &:hover{
                background-color: var(--primary-color-dark);
            }
        }
    }


    .faq{
        width: var(--main-width);
        margin: 0 auto;
        padding: 120px 0 100px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .header{
            text-align: center;
            width: fit-content;
            padding: 20px 20px 50px;

            h1{
                font-size: var(--text-xxl);
                color: var(--text-color-2);
                font-weight: 600;
                margin: 0;
            }
            p{
                font-size: var(--text-m);
                color: var(--text-color-2);
                margin: 5px;
            }
        }

        .questions{
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 100%;
            max-width: 800px;

            details{
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 20px;
                border-radius: 10px;
                background-color: var(--background-shade-2);
                box-shadow: inset 0 0 10px 5px rgba(0, 0, 0, 0.02);
                cursor: pointer;

                summary{
                    font-size: var(--text-l);
                    color: var(--text-color-1);
                    margin: 0;

                    &::marker {
                        color: var(--primary-color);
                        font-size: var(--text-xxl);
                    }
                }

                .answer{
                    padding-top: 15px;
                    p{
                        font-size: var(--text-s);
                        color: var(--text-color-3);
                        margin: 0;
                        line-height: 1.3;
                    }
                }
            }
        
        }
    }
}



@media screen and (max-width: 760px){
    .container .hero .imageContext{
        background-attachment: scroll;
    }
    .container .hero .imageContext .overlay{
        margin-left: 40px;

        h1{
            margin: 0;
            color: var(--text-color-4);
            font-size: var(--text-xxxl);
            font-weight: 600;
        }

        p{
            margin: 10px 0 0 0;
            color: var(--text-color-5);
            font-size: var(--text-m);
        }

        a{
            margin-top: 25px;
            padding: 10px 20px;
            background-color: var(--primary-color);
            color: white;
            text-decoration: none;
            border-radius: 5px;
            font-size: var(--text-s);
            transition: all .12s ease-in-out;
            width: fit-content;

            &:hover{
                background-color: var(--primary-color-dark);
            }
        }
    }

    .container .services {
        .content{
            text-align: left;
            align-items: start;
            padding-left: 10px;
        }

        .cards{
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .container .preview {


        .images{
            overflow-x: scroll;
            width: var(--main-width);

            .image{
                min-width: 70dvw;
                &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4){
                    opacity: 0.95;
                    &::after{
                        display: none;
                        content: '';
                    }
                }
                
            }
        }
    }

    .container .about{
        flex-direction: column;
        gap: 0;
        max-width: 100vw;
        margin: 0 auto;
        overflow-x: hidden;

        .left{
            width: calc(var(--main-width) - 40px);
            margin: 0 auto;
            padding: 25px 20px 20px 20px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            background-color: var(--background-shade-3);

            p{
                font-size: var(--text-m);
            }
        }

        .right{
            height: 250px;
            box-shadow: none;
            margin: 0;
            .image{
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }

    .container .beforeAfter{
        flex-direction: column;
        gap: 0;

        .header{
            text-align: center;
            width: fit-content;
            padding: 20px 20px 50px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            height: fit-content;


            h1{
                font-size: var(--text-xxl);
            }
            p{
                font-size: var(--text-m);
            }
            .buttons{
                width: fit-content;
                margin: 25px auto 0 auto;
            }
        }

        .compareWrapper{
            width: 100%;
            height: 330px;
            .compareSlider{
                width: 70%;
                height: 330px;
                margin: 0 auto;
            }
        }
    }

    .container .contact{
        padding: 50px 0 100px 0;
        h1{
            font-size: var(--text-xl);
        }
        p{
            font-size: var(--text-m);
        }
    }
}