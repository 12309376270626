.container{
    min-height: 100dvh;
    padding-bottom: 50px;
    .heading{
        width: var(--main-width);
        margin: 110px auto 30px;

        h1{
            font-size: var(--text-xxl);
            font-weight: 600;
            margin: 0;
        }
    }

    .wrapper{
        width: var(--second-width);
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .block{
            width: 80%;

            h2{
                margin: 0 0 6px 0;
                font-size: var(--text-l);
                font-weight: 500;
            }
            p{
                margin: 0;
                font-size: var(--text-m);
                line-height: 1.25;
            }

        }
    }
}